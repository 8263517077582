import React, { useContext, useEffect, useRef, useState } from "react";
import { FaSpinner, FaWhatsapp } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { FcOk } from "react-icons/fc";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { TbDiscOff } from "react-icons/tb";

interface QRCodeModalProps {
  onConfirm: () => void;
  onDisconnect: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isLoadingC: boolean;
  qrCodeData: string | null;
  selectedDevice?: any;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({
  onConfirm,
  onDisconnect,
  onCancel,
  qrCodeData,
  isLoading,
  isLoadingC,
  selectedDevice,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { userId } = useContext(AuthContext);
  const [isRtl, setIsRtl] = useState(false);

  const updateDirection = () => {
    const width = window.innerWidth;
    if (width <= 1025 || (width > 1025 && width <= 768)) {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  useEffect(() => {
    updateDirection();
    window.addEventListener("resize", updateDirection);
    return () => window.removeEventListener("resize", updateDirection);
  }, []);

  useEffect(() => {
    if (qrCodeData && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        const image = new Image();
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
        image.src = qrCodeData;
      }
    }
  }, [qrCodeData]);

  const rtlStyle: React.CSSProperties = isRtl ? { direction: "rtl" } : {};

  return (
    <div className="App">
      <div
        className="relative z-40"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25 z-0"></div>
        <div className="fixed inset-0 z-50 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
              <div className="pointer-events-auto w-screen max-w-xl sm:max-w-2xl">
                <div className="flex h-full rounded-l-xl flex-col overflow-y bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <div className="flex items-start border-b pb-2 border-gray-300 justify-between">
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={onCancel}
                        >
                          <IoMdArrowBack size={30} />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-start my-4">
                      <button
                        onClick={onDisconnect}
                        disabled={
                          isLoadingC ||
                          selectedDevice?.status === "Disconnected" ||
                          selectedDevice?.status === "disconnected"
                        }
                        className={`text-gray-700 flex gap-2 lg:mx-4 px-4 mb-4 py-2 border border-blue-400 leading-6 rounded-lg hover:bg-gray-50 transition-colors ${
                          isLoadingC ? "cursor-not-allowed opacity-50" : ""
                        }`}
                      >
                        <TbDiscOff className="mt-0.5" size={22} />
                        <span>فصل الجهاز</span>
                      </button>
                    </div>
                    <div className="mt-8">
                      <div className="flow-root" style={rtlStyle}>
                        <ul className="divide-gray-200 mx-auto w-full sm:grid md:grid lg:flex xl:flex justify-between items-center">
                          <li className="border px-2 border-gray-100 mx-6 rounded-md w-60 sm:w-60 md:w-60 h-60 sm:h-56 mt-6 mb-6">
                            {isLoading ? (
                              <div className="flex justify-center items-center h-full">
                                <FaSpinner className="animate-spin h-8 w-8 text-gray-500" />
                              </div>
                            ) : (
                              <div className="grid justify-center items-center h-full">
                                {qrCodeData ? (
                                  <div className="bg-gray-50 mx-0 rounded-lg shadow-sm">
                                    <canvas
                                      ref={canvasRef}
                                      width="255"
                                      height="260"
                                      aria-label="Scan me!"
                                      role="img"
                                    ></canvas>
                                  </div>
                                ) : (
                                  <div>
                                    {userId === selectedDevice?.id_users &&
                                      (selectedDevice?.status ===
                                      "AUTHENTICATED" ? (
                                        <FcOk
                                          size={40}
                                          className="h-16 w-16 text-green-500"
                                        />
                                      ) : (
                                        <FaWhatsapp className="h-16 w-16 text-green-500" />
                                      ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </li>
                          {selectedDevice?.status === "AUTHENTICATED" ? (
                            <ul
                              className="list-disc list-inside text-right max-w-md space-y-4"
                              dir="rtl"
                            >
                              <li className="flex items-start space-x-3">
                                <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                  <span className="text-lg font-bold text-blue-500">
                                    .
                                  </span>
                                </div>
                                <div className="text-md">
                                  حالة ريط الجهاز :{" "}
                                  <span className="font-bold text-blue-500">
                                    متصل
                                  </span>
                                </div>
                              </li>
                              <li className="flex items-start space-x-3">
                                <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                  <span className="text-lg font-bold text-blue-500">
                                    .
                                  </span>
                                </div>
                                <div className="text-md">
                                  رقم الواتس اب :{" "}
                                  <span className="font-bold text-blue-500">
                                    {selectedDevice.number}
                                  </span>
                                </div>
                              </li>
                              <li className="flex items-start space-x-3">
                                <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                  <span className="text-lg font-bold text-blue-500">
                                    .
                                  </span>
                                </div>
                                <div className="text-md">
                                  حالة الاتصال :{" "}
                                  <span
                                    className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                                      selectedDevice.payment_status === 1
                                        ? "bg-blue-100 text-blue-800"
                                        : "bg-red-100 text-red-800"
                                    }`}
                                  >
                                    {" "}
                                    {selectedDevice.payment_status === 1
                                      ? "مفعل"
                                      : "غير مفعل"}
                                  </span>
                                </div>
                              </li>
                              <li className="flex items-start space-x-3">
                                <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                  <span className="text-lg font-bold text-blue-500">
                                    .
                                  </span>
                                </div>
                                <div className="text-md">
                                  تاريخ الانتهاء :{" "}
                                  <span className="text-sm text-gray-500 mt-2">
                                    {" "}
                                    {new Date(selectedDevice.ExpiredDate) <
                                    new Date() ? (
                                      <Link to={"/promotions"}>
                                        <span className="bg-red-200 p-1 cursor-pointer rounded-lg text-red-800">
                                          تجديد الاشتراك
                                        </span>{" "}
                                      </Link>
                                    ) : (
                                      selectedDevice.ExpiredDate
                                    )}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <li
                              className="text-start grid justify-start px-2 mb-8 "
                              dir="rtl"
                            >
                              <h2 className="text-lg font-bold mb-2 text-[#337ce9]">
                                امسح رمز QR CODE
                              </h2>
                              <p className="text-gray-700 text-md mb-6">
                                اتبع الخطوات التالية للاتصال :
                              </p>
                              <ul className="list-disc list-inside text-right max-w-md space-y-4">
                                <li className="flex items-start space-x-3">
                                  <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                    <span className="text-lg font-bold">1</span>
                                  </div>
                                  <span className="text-gray-800">
                                    افتح WhatsApp على هاتفك.
                                  </span>
                                </li>
                                <li className="flex items-start space-x-3">
                                  <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                    <span className="text-lg font-bold">2</span>
                                  </div>
                                  <span className="text-gray-800">
                                    انتقل إلى القائمة{" "}
                                    <strong className="text-black">☰</strong> أو
                                    الإعدادات
                                    <strong className="text-black">⚙</strong>.
                                  </span>
                                </li>
                                <li className="flex items-start space-x-3">
                                  <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                    <span className="text-lg font-bold">3</span>
                                  </div>
                                  <span className="text-gray-800">
                                    حدد{" "}
                                    <strong className="text-black">
                                      {" "}
                                      WhatsApp Web.
                                    </strong>
                                  </span>
                                </li>
                                <li className="flex justify-start items-start space-x-3">
                                  <div className="flex-shrink-0 ml-3 w-6 h-6 bg-[#337ce9] text-white rounded-full flex items-center justify-center">
                                    <span className="text-lg font-bold">4</span>
                                  </div>
                                  <span className="text-gray-800">
                                    استخدم هاتفك لمسح الرمز المعروض هنا.
                                  </span>
                                </li>
                              </ul>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="flex justify-start my-4">
                      {selectedDevice?.status === "AUTHENTICATED" ? null : (
                        <button
                          onClick={onConfirm}
                          disabled={isLoading}
                          className={`bg-[#337ce9] text-white lg:mx-4 px-4 mb-4 py-2 leading-6 rounded-lg hover:bg-blue-600 transition-colors ${
                            isLoading ? "cursor-not-allowed opacity-50" : ""
                          }`}
                        >
                          QR Code توليد
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default QRCodeModal;
