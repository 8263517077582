import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserMasterContext } from "../../../../context/UserMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import AuthSelect from "../../messages/data/AuthSelect";

interface SendResult {
  status: "success" | "error";
  message: string;
}

const Createuser: React.FC = () => {
  const { loading } = useContext(UserMasterContext);
  const { token } = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedAuth, setSelectedAuth] = useState<any>(null);

  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [userNameError, setUserNameError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [sendResults, setSendResults] = useState<SendResult[]>([]);

  const resetErrors = () => {
    setUserNameError(null);
    setEmailError(null);
    setNameError(null);
    setPasswordError(null);
    setConfirmPasswordError(null);
    setAuthError(null);
    setSendResults([]);
  };

  const validateInputs = (): boolean => {
    let isValid = true;

    if (!username) {
      setUserNameError("يجب كتابة اسم المستخدم.");
      isValid = false;
    }
    if (!name) {
      setNameError("يجب كتابة الاسم الاول.");
      isValid = false;
    }
    if (!phone) {
      setPhoneError("يجب كتابة رقم الهاتف.");
      isValid = false;
    }

    if (!email) {
      setEmailError("يجب كتابة البريد الإلكتروني.");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("البريد الإلكتروني غير صالح.");
      isValid = false;
    }

    if (!selectedAuth) {
      setAuthError("يجب اختيار صلاحية.");
      isValid = false;
    }

    return isValid;
  };

  const validatePasswords = (): boolean => {
    let isValid = true;

    if (!password) {
      setPasswordError("يجب إدخال كلمة المرور.");
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError("يجب تأكيد كلمة المرور.");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("كلمة المرور غير متطابقة.");
      isValid = false;
    }

    return isValid;
  };

  const handleCreateUser = async () => {
    // resetErrors();

    if (!validateInputs() || !validatePasswords()) {
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("username", username);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("name", name);
    formData.append("password", password);
    formData.append("role", selectedAuth?.id || "");

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/CreateUser",
        formData,
        {
          headers: { "auth-token": token },
        }
      );
      //   console.log(response.data);
      if (response.data.status) {
        resetErrors();
        setSendResults((prev) => [
          ...prev,
          { status: "success", message: response.data.msg },
        ]);
      } else {
        setSendResults((prev) => [
          ...prev,
          { status: "error", message: response.data.msg },
        ]);
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setSendResults((prev) => [
        ...prev,
        { status: "error", message: "خطأ في النظام الداخلي." },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (sendResults.length > 0) {
      const timeout = setTimeout(() => setSendResults([]), 3000);
      return () => clearTimeout(timeout);
    }
  }, [sendResults]);

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center h-screen">
        <p className="text-gray-600">جار التحميل...</p>
      </div>
    );
  }

  return (
    <div className="p-8 w-full max-w-2xl mx-auto bg-white rounded shadow">
      <h1 className="text-lg font-bold mb-2">إضافة مستخدم جديد</h1>

      {sendResults.length > 0 && (
        <div className="mb-2">
          {sendResults.map((result, idx) => (
            <div
              key={idx}
              className={`p-3 rounded text-white ${
                result.status === "success" ? "bg-green-500" : "bg-red-500"
              }`}
            >
              {result.message}
            </div>
          ))}
        </div>
      )}
      <div className="mb-2">
        <label className="block text-sm mb-1">الصلاحيات</label>
        <AuthSelect
          onAuthSelect={setSelectedAuth}
          selectedOptions={selectedAuth}
          selectedAuthId={selectedAuth?.id || selectedAuth}
        />
        {authError && <p className="text-red-500 text-sm">{authError}</p>}
      </div>
      <div className="flex flex-wrap gap-1 w-full items-center">
        <div className="mb-4 flex-1">
          <label className="block text-sm mb-1">الاسم الاول</label>
          <input
            type="text"
            className="w-full border p-1.5 rounded"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
        </div>
        <div className="mb-4 flex-1">
          <label className="block text-sm mb-1">اسم المستخدم</label>
          <input
            type="text"
            className="w-full border p-1.5 rounded"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
          {userNameError && (
            <p className="text-red-500 text-sm">{userNameError}</p>
          )}
        </div>
      </div>
      <div className="mb-2">
        <label className="block text-sm mb-1">رقم الهاتف</label>
        <input
          type="tel"
          className="w-full border p-1.5 rounded"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
      </div>
      <div className="mb-2">
        <label className="block text-sm mb-1">البريد الإلكتروني</label>
        <input
          type="email"
          className="w-full border p-1.5 rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
      </div>

      <div className="mb-2">
        <label className="block text-sm mb-1">كلمة المرور</label>
        <input
          type="password"
          className="w-full border p-1.5 rounded"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {passwordError && (
          <p className="text-red-500 text-sm">{passwordError}</p>
        )}
      </div>

      <div className="mb-2">
        <label className="block text-sm mb-1">تأكيد كلمة المرور</label>
        <input
          type="password"
          className="w-full border p-1.5 rounded"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPasswordError && (
          <p className="text-red-500 text-sm">{confirmPasswordError}</p>
        )}
      </div>

      <button
        onClick={handleCreateUser}
        className={`w-full p-3 rounded text-white ${
          isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
        }`}
        disabled={isSubmitting}
      >
        {isSubmitting ? "جارٍ الإرسال..." : "إضافة المستخدم"}
      </button>
    </div>
  );
};

export default Createuser;
