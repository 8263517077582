import React from "react";
import { useUserBalance } from "../../../../context/balance/UserBalanceContext";
import { LuRefreshCcw } from "react-icons/lu";
import { FaSpinner } from "react-icons/fa";

const UserBalanceDisplay: React.FC = () => {
  const { userBalance, loading, error, fetchUserBalances } = useUserBalance();

  if (loading)
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  // console.log(userBalance)
  return (
    <div className="px-4 my-4 overflow-auto">
      <div className="bg-white rounded-sm shadow-sm max-h-screen hide-scrollbar px-0 scrollbar-light">
        <div>
          <div className="px-4 my-3">
            <span className="text-gray-600 text-xl font-normal">الرصيد</span>
          </div>
          <div className="flex p-2 mx-0">
            <div className="flex flex-col lg:flex-row gap-2 mb-4">
              <button
                onClick={() => fetchUserBalances()}
                className={`${
                  loading ? "cursor-not-allowed opacity-50" : ""
                } bg-slate-50 rounded-md p-1 px-6 shadow-sm`}
                disabled={loading}
                type="button"
              >
                <LuRefreshCcw className="text-blue-500 mt-2.5 mx-4" size={25} />
              </button>
            </div>
          </div>
          <div className="overflow-x-auto scrollbar-light">
            <table className="min-w-full border-separate border border-slate-200">
              <thead className="bg-slate-100 border-b border-gray-300">
                {/* Main Header Row */}
                <tr className="bg-slate-100 border-collapse shadow-sm">
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الاسم
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الرصيد الافتتاحي
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    العملة
                  </th>
                  <th className="px-4 py-2 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    مدين
                  </th>
                  <th className="px-4 py-2 font-semibold text-right text-xs text-gray-500 uppercase tracking-wider">
                    دائن
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الرصيد النهائي
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الرصيد بالعملة المحلية
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userBalance?.map((user: any) => (
                  <tr key={user.id} className="bg-gray-50 hover:bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {user.name}
                    </td>
                    <td
                      className={`px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700 ${
                        user.openbalance < 0
                          ? "bg-blue-300"
                          : user.openbalance > 0
                          ? "bg-red-300"
                          : ""
                      }`}
                    >
                      <span className="font-bold">
                        {Math.abs(user.openbalance)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>{" "}
                      {user.currency_name}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {user.currency}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                    {Math.abs(user.debit1)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                    {Math.abs(user.credit1)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td
                      className={`px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700 ${
                        user.closebalance < 0
                          ? "bg-blue-300"
                          : user.closebalance > 0
                          ? "bg-red-300"
                          : ""
                      }`}
                    >
                      <span className="font-bold">
                        {Math.abs(user.closebalance)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>{" "}
                      {user.currency_name}
                    </td>

                    <td
                      className={`px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700 ${
                        user.closebalancelocle < 0
                          ? "bg-blue-300"
                          : user.closebalancelocle > 0
                          ? "bg-red-300"
                          : ""
                      }`}
                    >
                      <span className="font-bold">
                        {Math.abs(user.closebalancelocle)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBalanceDisplay;
