import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";

interface PlanHomeContextType {
  planData: any | null;
  isloading: boolean;
  fetchPlanMaster: () => void;
}

export const PlanHomeContext = createContext<PlanHomeContextType>({
  planData: null,
  isloading: false,
  fetchPlanMaster: () => {},
});

const PlanHomeContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [planData, setPlanData] = useState<any | null>(null);
  const [isloading, setIsLoading] = useState<boolean>(false);

  const fetchPlanMaster = useCallback(async () => {
    // if (!token) return;
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://click.one.sky4system.com/api/user/GeTPlanData"
      );

      // console.log(response.data);

      setPlanData(response.data);
    } catch (error) {
      console.log("Failed to fetch planData data", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPlanMaster();
  }, [fetchPlanMaster]);

  return (
    <PlanHomeContext.Provider value={{ planData, isloading, fetchPlanMaster }}>
      {children}
    </PlanHomeContext.Provider>
  );
};

export default PlanHomeContextProvider;
