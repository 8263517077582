import React, { useEffect, useState } from "react";
import { useUserDepositHistory } from "../../../../context/balance/UserDepositHistoryContext";
import { LuRefreshCcw } from "react-icons/lu";
import { FaSpinner } from "react-icons/fa";
import TimePickerComponentGrid from "../../messages/campaign/TimePickerComponentGrid";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiFilter, CiRead } from "react-icons/ci";
import * as XLSX from "xlsx";
// import { GrPrint } from "react-icons/gr";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import TransactionDialog from "./TransactionDialog";

const UserDepositDisplay: React.FC = () => {
  const {
    UserDepositHistory,
    loading,
    error,
    setFilters,
    filters,
    fetchUserDepositHistorys,
  } = useUserDepositHistory();
  const [localFilters, setLocalFilters] = useState({
    status: "",
    from_amount: "",
    to_amount: "",
    from_date: "",
    to_date: "",
    Search: "",
  });
  const [selectedMasterId, setSelectedMasterId] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleRowClick = (masterId: number) => {
    setSelectedMasterId(masterId);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedMasterId(null);
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const applyFilters = () => {
    const updatedFilters: any = {};

    if (localFilters.status !== "") {
      updatedFilters.status = localFilters.status;
    }

    if (localFilters.from_amount) {
      updatedFilters.from_amount = localFilters.from_amount;
    }

    if (localFilters.to_amount) {
      updatedFilters.to_amount = localFilters.to_amount;
    }

    if (localFilters.from_date) {
      updatedFilters.from_date = localFilters.from_date;
    }

    if (localFilters.to_date) {
      updatedFilters.to_date = localFilters.to_date;
    }

    if (localFilters.Search) {
      updatedFilters.Search = localFilters.Search;
    }

    setFilters(updatedFilters);
  };

  const exportToExcelXLSX = () => {
    const reportContent = document.getElementById("printable-report");

    if (reportContent) {
      // Extract table data
      const data: any[][] = [];
      const rows = reportContent.querySelectorAll("tr");

      rows.forEach((row) => {
        const rowData: any[] = [];
        row.querySelectorAll("th, td").forEach((cell) => {
          rowData.push((cell as HTMLElement).innerText);
        });
        data.push(rowData);
      });

      // Create a workbook and worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Adjust column widths
      worksheet["!cols"] = data[0]?.map((_, colIndex) => {
        // Increase width for "Details" column (e.g., column 2)
        if (colIndex === 3) {
          return { width: 80, hieght: 4 };
        }
        return { width: 15, hieght: 4 };
      });

      // Apply styles to the header row
      const headerRange = XLSX.utils.decode_range(worksheet["!ref"]!);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "4CAF50" } }, // Green header background
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
      }

      // Create a workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Deposit");

      // Export to XLSX
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
        cellStyles: true, // Ensure styles are applied
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const newTab = window.open(url, "_blank");
      if (newTab) {
        newTab.document.write(`
          <a href="${url}" download="report.xlsx" style="font-size: 20px; margin: 20px; display: block;">
            Click here to download the Excel file
          </a>
        `);
      }
    }
  };

  // const printReport = () => {
  //   const printContent = document.getElementById("printable-report");

  //   if (printContent) {
  //     // Create a new window
  //     const printWindow = window.open("", "_blank");

  //     if (printWindow) {
  //       // Write the content to the new window
  //       printWindow.document.open();
  //       printWindow.document.write(`
  //         <html>
  //           <head>
  //             <title>Print</title>
  //             <style>
  //               body {
  //                 font-family: Arial, sans-serif;
  //                 margin: 20px;
  //               }
  //               table {
  //                 width: 100%;
  //                 border-collapse: collapse;
  //               }
  //               th, td {
  //                 border: 1px solid #ddd;
  //                 padding: 8px;
  //                 text-align: left;
  //               }
  //               th {
  //                 background-color: #f2f2f2;
  //               }
  //             </style>
  //           </head>
  //           <body>
  //             ${printContent.outerHTML}
  //           </body>
  //         </html>
  //       `);
  //       printWindow.document.close();

  //       // Trigger the print dialog
  //       printWindow.print();

  //       // Close the window after printing (optional)
  //       printWindow.onafterprint = () => {
  //         printWindow.close();
  //       };
  //     }
  //   }
  // };

  if (loading)
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="px-4 my-4">
      <div className="bg-white rounded-sm shadow-sm max-h-screen hide-scrollbar px-0 scrollbar-light">
        <div>
          <div className="px-4 my-3">
            <span className="text-gray-600 text-xl font-normal">
              حركة الايداع
            </span>
          </div>
          <div
            dir="rtl"
            className="grid grid-cols-1 md:grid-cols-4 gap-1 mx-0 p-1 my-2 bg-slate-100 border border-gray-300 rounded-sm"
          >
            {/* status Filter */}

            <div>
              <label htmlFor="status" className="block mb-0.5">
                حالة الايداع:
              </label>
              <select
                id="status"
                value={localFilters.status}
                onChange={(e) =>
                  setLocalFilters({ ...localFilters, status: e.target.value })
                }
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              >
                <option value="">الكل</option>
                <option value="pending">قيد الانتظار</option>
                <option value=""></option>
              </select>
            </div>

            {/* Search Filter */}

            <div>
              <label htmlFor="search" className="block mb-0.5">
                بحث:
              </label>
              <input
                type="text"
                id="search"
                value={localFilters.Search}
                onChange={(e) =>
                  setLocalFilters({ ...localFilters, Search: e.target.value })
                }
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                placeholder="بحث..."
              />
            </div>

            {/* To Number Filter */}
            <div>
              <label htmlFor="from_amount" className="block mb-0.5">
                المبلغ من:
              </label>
              <input
                type="text"
                id="from_amount"
                value={localFilters.from_amount}
                onChange={(e) =>
                  setLocalFilters({
                    ...localFilters,
                    from_amount: e.target.value,
                  })
                }
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                placeholder="المبلغ"
              />
            </div>
            <div>
              <label htmlFor="to_amount" className="block mb-0.5">
                المبلغ الى:
              </label>
              <input
                type="text"
                id="to_amount"
                value={localFilters.to_amount}
                onChange={(e) =>
                  setLocalFilters({
                    ...localFilters,
                    to_amount: e.target.value,
                  })
                }
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                placeholder="المبلغ الى"
              />
            </div>

            {/* From Date Filter */}
            <div>
              <label htmlFor="from_date" className="block mb-0.5">
                تاريخ البدء:
              </label>
              <TimePickerComponentGrid
                initialValue={
                  localFilters.from_date
                    ? new Date(localFilters.from_date)
                    : null
                }
                onChange={(newValue) => {
                  const formattedDate =
                    newValue instanceof Date ? newValue.toISOString() : "";
                  setLocalFilters((prevFilters) => ({
                    ...prevFilters,
                    from_date: formattedDate,
                  }));
                }}
              />
            </div>

            {/* To Date Filter */}
            <div>
              <label htmlFor="to_date" className="block mb-0.5">
                الى نهاية تاريخ:
              </label>
              <TimePickerComponentGrid
                initialValue={
                  localFilters.to_date ? new Date(localFilters.to_date) : null
                }
                onChange={(newValue) => {
                  const formattedDate =
                    newValue instanceof Date ? newValue.toISOString() : "";
                  setLocalFilters((prevFilters) => ({
                    ...prevFilters,
                    to_date: formattedDate,
                  }));
                }}
              />
            </div>
            <div>
              <button
                onClick={applyFilters}
                disabled={loading}
                className={`px-4 py-1.5 flex sm:mt-6 mt-1 items-center gap-2 bg-indigo-100 rounded ${
                  loading
                    ? "cursor-not-allowed opacity-50"
                    : "hover:bg-indigo-200"
                } transition-colors duration-200`}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters
                    className="animate-spin"
                    size={20}
                  />
                ) : (
                  <CiFilter size={20} />
                )}
                <span>تطبيق الفلتر</span>
              </button>
            </div>
          </div>
          <div className="flex p-2 mx-0">
            <div className="flex flex-col lg:flex-row gap-2 mb-4">
              <button
                onClick={() => fetchUserDepositHistorys()}
                className={`${
                  loading ? "cursor-not-allowed opacity-50" : ""
                } bg-slate-50 rounded-md p-1 px-6 shadow-sm`}
                disabled={loading}
                type="button"
              >
                <LuRefreshCcw className="text-blue-500 mt-2.5 mx-4" size={25} />
              </button>
            </div>
            <div className="flex mx-2 space-x-2">
              <button
                onClick={exportToExcelXLSX}
                className="px-6 text-green-700 rounded shadow-sm"
              >
                <PiMicrosoftExcelLogoFill
                  size={20}
                  className="text-green-700"
                />
              </button>
              {/* <button
                onClick={printReport}
                className=" px-6 hover:text-blue-500 text-blue-600 rounded shadow-sm"
              >
                <GrPrint size={20} />
              </button> */}
            </div>
          </div>
          <div
            id="printable-report"
            className="scrollbar-light overflow-x-auto"
          >
            <table className="min-w-full max-h-screen border-collapse">
              <thead className="bg-slate-100 border-b border-gray-300">
                <tr className="bg-slate-100 border-collapse shadow-sm">
                  <th className="px-4 py-3 font-semibold text-center text-xs text-gray-600 uppercase tracking-wider">
                    تفاصيل القيد
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الوكيل
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    المستخدم
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    المبلغ
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    سعر الصرف
                  </th>
                  
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    البيان
                  </th>
                  <th className="px-4 py-3 font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    الحالة
                  </th>
                  <th className="px-4 py-3 w-auto font-semibold text-right text-xs text-gray-600 uppercase tracking-wider">
                    تاريخ القيد
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {UserDepositHistory?.sort(
                  (a, b) =>
                    new Date(b.updated_at).getTime()-
                    new Date(a.updated_at).getTime()
                ).map((deposit) => (
                  <tr
                    key={deposit.id}
                    className={`hover:bg-gray-200 ${(() => {
                      // Get the current date and subtract 1 day and 1 hour
                      const now = new Date();
                      now.setDate(now.getDate());

                      return new Date(deposit.updated_at).toDateString() ===
                        now.toDateString()
                        ? "bg-green-50"
                        : "";
                    })()}`}
                  >
                    
                    <td
                      onClick={() => handleRowClick(deposit.MasterId)}
                      className="px-16 py-3 cursor-pointer font-bold m-1 rounded-sm bg-blue-200 whitespace-nowrap text-right text-xs text-gray-700"
                    >
                      <CiRead size={20} />
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {deposit.AgentUserName}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {deposit.UserName}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      <span className="font-bold">
                        {Math.abs(parseFloat(deposit.amount || "0"))
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </td>
                    <td className="py-3 px-10 whitespace-nowrap text-right text-xs text-gray-700">
                      <span className="font-bold">
                        {Math.abs(parseFloat(deposit.rate || "0"))
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {deposit.details}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {deposit.status}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right text-xs text-gray-700">
                      {new Intl.DateTimeFormat("ar-EG", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(deposit.updated_at))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TransactionDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        masterId={selectedMasterId}
      />
    </div>
  );
};

export default UserDepositDisplay;
