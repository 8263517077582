import React, { useContext, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { AuthorizeUserContext } from "../../../../context/AuthorizeUserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import CheckBoxToggle from "../../devices/settings/CheckBoxToggle";
import { IoMdArrowForward } from "react-icons/io";

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}

type PermissionValue = number | Permission;

type Permission = {
  [label: string]: PermissionValue;
};

type PermissionsState = {
  [group: string]: {
    [permission: string]: PermissionValue; // Updated to allow number or Permission
  };
};

const CreateAuthorizeUsers: React.FC = () => {
  // Renamed component
  const { token } = useContext(AuthContext);

  const { fetchAuthorizeUserData, loading } = useContext(AuthorizeUserContext);
  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const [authName, setAuthName] = useState(""); // Renamed setter for consistency
  const [isLoading, setIsLoading] = useState<boolean>(false); // Renamed state
  const [authNameError, setAuthNameError] = useState<string | null>(null); // Initialized with null
  const [permissions, setPermissions] = useState<PermissionsState>({
    contact: {
      view: {
        "جهات الاتصال": 0,
      },
      edit: {
        "تعديل جهات الاتصال": 0,
      },
    },
    groupContact: {
      Create: {
        "أنشاء مجموعات جهات الاتصال": 0,
      },
      CreatGroup: {
        "أضافة مجموعة جهة اتصال": 0,
      },
      CreatContact: {
        "أضافة جهة اتصال": 0,
      },
      ViewGroup: {
        "عرض مجموعات جهات الاتصال": 0,
      },
      DeleteGroup: {
        "حذف مجموعة جهات الاتصال": 0,
      },
    },
    Device: {
      view: {
        الاجهزه: 0,
      },
      add: {
        "اضافه جهاز": 0,
      },
      Settings: {
        "أعدادات الجهاز": 0,
      },
      Delete: {
        "حذف الجهاز": 0,
      },
    },
    Promotion: {
      view: {
        الترويج: 0,
      },
      sendcampaign: {
        "رسائل مجدولة": 0,
      },
      sendmessages: {
        "ارسال رسالة نص": 0,
      },
      sendfilemessages: {
        "رسائل ملفات": 0,
      },
      codegroup: {
        "ترميز المجموعات": 0,
      },
    },
    groupsm: {
      view: {
        "رسائل للمجموعات": 0,
      },
      Sendmessages: {
        "رسائل نصيه للمجموعات": 0,
      },
      Sendfilemessages: {
        "رسائل ملفات ": 0,
      },
    },
    reports: {
      view: {
        التقارير: 0,
      },
      resend: {
        "التقارير عرض": 0,
      },
    },
  });

  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const navigate = useNavigate();

  const resetErrors = () => {
    setAuthNameError(null);
    setSendResults([]);
  };
  const clearMessages = () => setSendResults([]);

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.errNum === "S000") {
      result = {
        number,
        status: "success",
        message: `تم تأكيد العملية بنجاح `,
      };
      successSend = {
        text: `تمت عملية التأكيد `,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `حدث خطأ 403`,
      };
      successSend = {
        text: `حدث خطأ 403`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: `حدث خطأ 401`,
      };
      successSend = { text: `حدث خطأ 401`, type: "error" };
    } else {
      result = { number, status: "error", message: `فشل في تأكيد البيانات` };
      successSend = { text: `فشل في تحديث البيانات `, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  const validateInputs = (): boolean => {
    if (!authName.trim()) {
      // Added trim to avoid spaces
      setAuthNameError("يجب كتابة اسم صلاحية المستخدم.");
      return false;
    }
    return true;
  };

  const handleUpdateAuthUser = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);

    const formData = {
      id: "0",
      name: authName,
      permissions: permissions,
    };

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/Save-Role",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (response.data.status === true) {
        fetchAuthorizeUserData();
        setTimeout(() => {
          navigate("/authorize-user");
        }, 1000);
      } else {
        // Clear messages after 3 seconds
        setTimeout(() => {
          clearMessages();
        }, 2000);
      }
      const result = await getSendResult(response.data, ""); // Await directly
      setSuccessSend(result.successSend);
      return result.sendResult;
    } catch (error: any) {
      // Added type for error
      console.log("Internal server error 500:", error);
      // Optionally, set an error message for the user
      const result = await getSendResult({ errNum: "500" }, "");
      setSuccessSend(result.successSend);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePermissionChange = (permissionKey: string) => {
    // Use '||' as the delimiter to avoid conflicts with existing dots in keys
    const keys = permissionKey.split("||");
    const [group, item, subItem] = keys;

    setPermissions((prevPermissions: any) => {
      const currentGroup = { ...(prevPermissions[group] ?? {}) };
      const currentItem = { ...(currentGroup[item] ?? {}) };

      if (subItem) {
        const currentValue = currentItem[subItem] as number | undefined;
        const newValue = currentValue === 1 ? 0 : 1;

        return {
          ...prevPermissions,
          [group]: {
            ...currentGroup,
            [item]: {
              ...currentItem,
              [subItem]: newValue,
            },
          },
        };
      } else {
        const currentValue = currentItem as number | undefined;
        const newValue = currentValue === 1 ? 0 : 1;

        return {
          ...prevPermissions,
          [group]: {
            ...currentGroup,
            [item]: newValue,
          },
        };
      }
    });
  };

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 my-4 overflow-auto" dir="rtl">
      <div className="mt-8 mx-2 flex justify-center gap-4 hide-scrollbar">
        {/* Notifications */}

        {sendResults.length > 0 && (
          <div className="fixed top-16 right-8 flex flex-col gap-3 z-50">
            {sendResults.map((result, index) => (
              <div
                key={`${result.number}-${index}`} // Improved key
                className={`flex gap-3 w-auto p-2 rounded-lg shadow-lg opacity-90 ${
                  result.status === "error"
                    ? "bg-red-500 text-white"
                    : "bg-green-600 text-white"
                }`}
              >
                <button onClick={clearMessages} className="cursor-pointer">
                  <span className="p-1 text-lg text-gray-100">X</span>
                </button>

                <div>
                  <p className="text-white">{result.message}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="sm:w-10/12 mx-auto w-full relative">
          <div className="my-6 absolute top-12 w-full flex justify-center">
            {successSend && (
              <p
                className={`text-sm mt-1 firework-message ${
                  successSend.type === "error"
                    ? "error-message"
                    : "success-message"
                }`}
              >
                {successSend.text}
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </p>
            )}
          </div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center mb-4 rounded-lg p-2 hover:bg-gray-100 transition"
            aria-label="Go Back"
          >
            <IoMdArrowForward className="w-6 h-6 mr-2" />
            <span>رجوع</span> {/* Optional: Add text for better UX */}
          </button>
          <div className="mb-4">
            <div className="w-full">
              <label htmlFor="authName" className="block">
                <span className="mr-4">اسم الصلاحية</span>
              </label>
              <div className="mb-4">
                <input
                  type="text" // Corrected input type
                  value={authName}
                  className={`w-full p-2 border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 rounded mt-1`}
                  onChange={(e) => setAuthName(e.target.value)}
                  id="authName"
                  name="authName"
                />
              </div>
              {authNameError && (
                <p className="text-red-500 text-sm">{authNameError}</p>
              )}
            </div>
          </div>
          {Object.entries(permissions).map(([groupKey, groupValue]) => (
            <div key={groupKey} className="mb-4">
              <h3 className="font-semibold">{groupKey}</h3>
              {Object.entries(groupValue).map(([itemKey, itemValue]) => {
                if (typeof itemValue === "object" && itemValue !== null) {
                  return (
                    <div key={`${groupKey}||${itemKey}`} className="ml-4">
                      {Object.entries(itemValue).map(
                        ([subItemKey, subItemValue]) => (
                          <div
                            key={`${groupKey}||${itemKey}||${subItemKey}`}
                            className="flex items-center ml-4"
                          >
                            <CheckBoxToggle
                              initialChecked={Boolean(subItemValue)} // Convert number to boolean
                              onChange={() =>
                                handlePermissionChange(
                                  `${groupKey}||${itemKey}||${subItemKey}`
                                )
                              }
                            />
                            <span className="ml-2">{subItemKey.trim()}</span>
                          </div>
                        )
                      )}
                    </div>
                  );
                }

                // Render top-level permissions (non-nested)
                return (
                  <div
                    key={`${groupKey}||${itemKey}`}
                    className="flex items-center ml-4"
                  >
                    <CheckBoxToggle
                      initialChecked={Boolean(itemValue)} // Convert number to boolean
                      onChange={() =>
                        handlePermissionChange(`${groupKey}||${itemKey}`)
                      }
                    />
                    <span className="ml-2">{itemKey.trim()}</span>
                  </div>
                );
              })}
            </div>
          ))}
          <div className="my-4">
            <button
              type="button"
              className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
                isLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={handleUpdateAuthUser}
              disabled={isLoading}
            >
              {isLoading ? "جاري التأكيد..." : "تأكيد"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAuthorizeUsers;
