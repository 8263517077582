// Example usage in a component
import React from 'react';
// import useAuthenticatedApi from '../hooks/useAuthenticatedApi';

const SomeComponent: React.FC = () => {
  // const { apiCall } = useAuthenticatedApi();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await apiCall('/');
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error('Error fetching data', error);
  //     }
  //   };

  //   fetchData();
  // }, [apiCall]);

  return (
    <div>
      {/* <h1 className='mx-auto'>Some Protected Component</h1> */}
      {/* Render component content */}
    </div>
  );
};

export default SomeComponent;
