// src/components/MediaGroupComponent.tsx
import React from "react";
import { AiFillFileImage } from "react-icons/ai";
import MediaSendGroup from "./MediaSendGroup";

const MediaGroupComponent: React.FC = () => {
  return (
    <div className="border-b border-gray-300 justify-start container">
      <div className="flex justify-start gap-3 mx-16 text-lg mt-8">
        <AiFillFileImage size={25} className="text-cyan-600" />
        <span>رسائل ملفات </span>
      </div>
      <MediaSendGroup />
    </div>
  );
};

export default MediaGroupComponent;
