import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const navigateToSection = (section: string) => {
    navigate(`/?v=${section}`);
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <footer className="bg-gradient-to-r from-[#142f4b] pb-6 to-[#004875] via-[#046491] text-gray-100 py-6">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-10">
        <div className="flex flex-wrap justify-between" dir="rtl">
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-8 lg:mb-0">
            <div className="flex justify-center lg:justify-start mb-6">
              <img
                className="h-28 w-28 object-contain"
                src="/logo_w.png"
                sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                alt="Logo"
                loading="lazy"
              />
            </div>
          </div>

          {/* Contact Information */}
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-8 lg:mb-0">
            <h2 className="text-xl font-semibold mb-4">تواصل معنا</h2>
            <address className="space-y-4 py-2 my-2">
              <p className="text-sm">
                <span>اليمن صنعاء</span>
                <br />
                <span>شارع مقديشو صخر سابقاً</span>
                <br /><span>00967777288199 - 009671535960</span>
                <br />
                <a href="mailto:Info@Sky4system.Com" className="text-gray-300 hover:underline">
                  info@alawael.com
                </a>
              </p>
            </address>
          </div>

          {/* Links */}
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-8 lg:mb-0">
            <h2 className="text-xl font-semibold mb-4">روابط</h2>
            <ul className="space-y-3">
              <li>
                <span
                  onClick={() => navigateToSection("home")}
                  className="text-gray-100 hover:text-cyan-800 transition-colors duration-300 cursor-pointer"
                >
                  الرئيسية
                </span>
              </li>
              <li>
                <span
                  onClick={() => navigateToSection("pricing")}
                  className="text-gray-100 hover:text-cyan-800 transition-colors duration-300 cursor-pointer"
                >
                  الأسعار
                </span>
              </li>
              <li>
                <span
                  onClick={() => navigateToSection("services")}
                  className="text-gray-100 hover:text-cyan-800 transition-colors duration-300 cursor-pointer"
                >
                  الخدمات
                </span>
              </li>
              <li>
                <a
                  href="https://documenter.getpostman.com/view/18114527/2sAXjDfG66"
                  className="text-gray-100 hover:text-cyan-800 transition-colors duration-300"
                >
                  APIs
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center lg:justify-center space-x-4">
          <a
            href="https://www.facebook.com/alawael.for.info?mibextid=ZbWKwL"
            className="text-gray-100 hover:text-cyan-800 transition-colors duration-300"
          >
            <FaFacebook size={20} />
          </a>
          <a
            href="https://www.x.com/"
            className="text-gray-100 hover:text-cyan-800 transition-colors duration-300"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://www.instagram.com/"
            className="text-gray-100 hover:text-cyan-800 transition-colors duration-300"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://www.linkedin.com/"
            className="text-gray-100 hover:text-cyan-800 transition-colors duration-300"
          >
            <FaLinkedin size={20} />
          </a>
        </div>
        {/* Footer Bottom */}
        {/* <div className="border-t border-slate-400 py-4 mt-8 text-center">
          <p className="text-sm">
            <span className="flex gap-1 items-center justify-center">
              © 2024 Powered By <FaHeart color="#f59e0b" />{" "}
              <a href="https://skysystem.dev/en" className="text-amber-400 hover:underline">
                SkySystems
              </a>{" "}
              All rights reserved.
            </span>
          </p>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
