import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";

interface DeviceMasterContextType {
  loading: boolean;
  deviceData: any | null;
  fetchDeviceMaster: () => void;
  errorMessage: string | null;
}

export const DeviceMasterContext = createContext<DeviceMasterContextType>({
  deviceData: null,
  loading: false,
  fetchDeviceMaster: () => {},
  errorMessage: null,
});

const DeviceMasterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { token } = useContext(AuthContext);
  const [deviceData, setDeviceData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Fetch the latest device master data
  const fetchDeviceMaster = useCallback(async () => {
    if (!token) return;
    setLoading(true);
    setErrorMessage(null); // Reset the error message before each request

    try {
      // const formData = new FormData();
      const response = await fetch(
        "https://click.one.sky4system.com/api/user/getdevicmaster?token=" + token,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 500) {
          setErrorMessage("Server error: Internal server error (500).");
        } else {
          setErrorMessage(
            `Error: ${response.statusText || "Contact Support."}`
          );
        }
      } else {
        const data = await response.json();
        // console.log(data);
        setDeviceData(data);
      }
    } catch (error) {
      setErrorMessage(
        "Network error: Unable to reach the server. Please check your internet connection."
      );
      console.log("Failed to fetch device master data", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchDeviceMaster();
  }, [fetchDeviceMaster]);

  return (
    <DeviceMasterContext.Provider
      value={{ deviceData, loading, fetchDeviceMaster, errorMessage }}
    >
      {children}
    </DeviceMasterContext.Provider>
  );
};

export default DeviceMasterProvider;
