import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import Header from "../Header";
import classNames from "classnames";

const Layout: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setSidebarOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      dir="rtl"
      className="h-screen overflow-y-auto scrollbar-light selection:bg-red-100"
    >
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <main
        className={classNames(
          "flex-1 pb-0 bg-white hide-scrollbar transition-all duration-300 ease-in-out",
          {
            "mr-2 sm:mr-16": !sidebarOpen,
            "mr-0 sm:mr-64": sidebarOpen,
          }
        )}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
