import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
// import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import { useNavigate, useParams } from "react-router-dom";
import { UserMasterContext } from "../../../../context/UserMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import { FiEye, FiEyeOff } from "react-icons/fi";
import AuthSelect from "../../messages/data/AuthSelect";

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}

const UpdateUsersData: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { fetchUserById, fetchUserMaster, loading, setLoading } =
    useContext(UserMasterContext);
  const { token } = useContext(AuthContext);

  const [isLoadings, setIsLoading] = useState<boolean>(false);
  const [isLoadingse, setIsLoadinge] = useState<boolean>(false);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [username, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState<string | null>("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setconfirmPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedAuth, setSelectedAuth] = useState<any>(null);
  const [authError, setAuthError] = useState<string | null>(null);

  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const navigate = useNavigate();

  const resetErrors = () => {
    setUserNameError(null);
    setPasswordError(null);
    setconfirmPasswordError(null);
    setAuthError(null);
    setSendResults([]);
  };

  const clearMessages = () => setSendResults([]);

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.errNum === "S000") {
      result = {
        number,
        status: "success",
        message: `تم تأكيد العملية بنجاح `,
      };
      successSend = {
        text: `تمت عملية التأكيد `,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `Error occure 403`,
      };
      successSend = {
        text: `Error occure 403`,
        type: "error",
      };
    } else if (data.errNum === "E002") {
      result = {
        number,
        status: "error",
        // message: `يجب ان يحتوي كلمة مرور 6 احرف على الاقل`,
        message: `يجب تعيين كلمة المرور`,
      };
      successSend = {
        text: `يجب تعيين كلمة المرور`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: ` لا ينتمي المستخدم إلى هذا الوكيل`,
      };
      successSend = {
        text: ` لا ينتمي المستخدم إلى هذا الوكيل`,
        type: "error",
      };
    } else {
      result = { number, status: "error", message: `فشل في تأكيد البيانات` };
      successSend = { text: `فشل في تحديث البيانات `, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  const validateInputs = (): boolean => {
    if (!username) {
      setUserNameError("يجب كتابة اسم البريد الالكتروني.");
      return false;
    }

    if (!selectedAuth) {
      setAuthError("يجب اختيار تحديد صلاحية.");
      return false;
    }
    return true;
  
  };
  
  const validateInputsPassword = (): boolean => {
    if (!confirmPassword) {
      setconfirmPasswordError("يجب تأكيد كلمة المرور.");
      return false;
    }
    if (password !== confirmPassword) {
      setconfirmPasswordError("كلمة المرور غير متطابقة.");
      return false;
    }
    return true;
  };
  // console.log(selectedAuth);
  useEffect(() => {
    const fetchData = async () => {
      if (fetchUserById && id) {
        setLoading(true);
        try {
          const UsersData = await fetchUserById(id);
          // console.log(UsersData);
          if (UsersData) {
            const { username, user, email, isagen, id } =
              UsersData || {};
            // setPassword(password || "");
            setUserName(username || "");
            setEmail(email || "");
            setUserId(id || user || "");
            setSelectedAuth(user || isagen || null);
          } else {
            console.log("No user data received");
          }
        } catch (error) {
          console.log("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [id, fetchUserById, setLoading]);

  const handleUpdateUser = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id", `${id}`);
    formData.append("username", username);
    // formData.append("password", password);
    formData.append("role_id", selectedAuth?.id || selectedAuth || "");
    formData.append("user_id", userId);

    // console.log(selectedAuth?.id)
    // console.log(selectedAuth)

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/UpdateUserMaster",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      // console.log(response.data);

      if (response.data.status === true) {
        fetchUserMaster();
        // console.log(response.data);
        const timeout = setTimeout(() => {
          navigate("/user-settings");
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          clearMessages();
        }, 2000);
      }
      const result = getSendResult(response.data, "");
      setSuccessSend((await result).successSend);
      return (await result).sendResult;
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUserResetPassowrd = async () => {
    resetErrors();
    if (!validateInputsPassword()) return;
    setIsLoadinge(true);

    const formData = new FormData();
    formData.append("id", userId);
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/ResetUserPassword",
        formData,
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      console.log(response.data);

      if (response.data.status === true) {
        fetchUserMaster();
        // console.log(response.data);
        const timeout = setTimeout(() => {}, 1000);
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          clearMessages();
        }, 2000);
      }
      const result = getSendResult(response.data, "");
      setSuccessSend((await result).successSend);
      return (await result).sendResult;
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoadinge(false);
    }
  };

  useEffect(() => {
    if (successSend) {
      const timeout = setTimeout(() => setSuccessSend(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [successSend]);

  if (loading) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-16">
        <div className="max:w-6xl py-12 px-16">انتظار التحميل...</div>
      </div>
    );
  }
  // console.log(userDataId);
  return (
    <div
      className="pt-8 mx-2 h-screen justify-center gap-4 hide-scrollbar"
      dir="rtl"
    >
      {/* Notifications */}

      {sendResults.length > 0 && (
        <div>
          {sendResults?.map((result, index) => (
            <div
              key={index}
              className={`absolute top-16 left-8 flex gap-3 w-auto p-2  ${
                result.status === "error" ? "bg-red-500" : "bg-green-600"
              } text-white text-sm rounded-lg shadow-lg z-50 opacity-75`}
            >
              <button onClick={clearMessages} className="cursor-pointer mt-1">
                <span className="p-1 text-lg text-gray-100">X</span>
              </button>

              <div className="mt-2">
                <p
                  className={`${
                    result.status === "error" ? "text-white" : "text-white"
                  }`}
                >
                  {result.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="sm:w-10/12 mx-auto w-full mb-4 border border-gray-200 p-2 rounded-lg">
        <div className="bg-slate-100 border border-gray-200 p-1">
          <span className="text-gray-600 font-normal">صلاحيات المستخدم</span>
        </div>
        <div className="mb-4">
          <div className="items-left w-3xl">
            <label htmlFor="username">
              <span className="mr-0 text-sm">اسم المستخدم</span>
            </label>
            <div className="mb-4">
              <input
                type="text"
                value={username || ""}
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                onChange={(e) => setUserName(e.target.value)}
                id="username"
                name="username"
                disabled
              />
            </div>
            {userNameError && (
              <p className="text-red-500 text-sm">{userNameError}</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label className="flex-inline text-sm">الصلاحيات</label>
          <div className="relative">
            <AuthSelect
              onAuthSelect={setSelectedAuth}
              selectedOptions={selectedAuth}
              selectedAuthId={selectedAuth?.id || selectedAuth}
            />
          </div>
          {/* {selectedAuth?.name}
          {selectedAuth?.id} */}
          {authError && <p className="text-red-500 text-sm">{authError}</p>}
        </div>
        <div className="my-4">
          <button
            type="button"
            className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
              isLoadings ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleUpdateUser}
            disabled={isLoadings}
          >
            {isLoadings ? "جاري التأكيد..." : "تأكيد"}
          </button>
        </div>
      </div>
      <div className="sm:w-10/12 mx-auto w-full border border-gray-200 p-2 rounded-lg">
        <div className="bg-slate-100 border border-gray-200 p-1">
          <span className="text-gray-600 font-normal">
            تغيير كلمة مرور المستخدم
          </span>
        </div>
        <div className="mb-4">
          <div className="items-left w-3xl">
            <label htmlFor="username">
              <span className="mr-0 text-sm">البريد الاكتروني</span>
            </label>
            <div className="mb-4">
              <input
                type="email"
                value={email || ""}
                className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                name="email"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label className="flex-inline">كلمة المرور</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
            <button
              type="button"
              className="absolute inset-y-0 left-0 flex items-center px-2" // Adjusted to position right for eye icon
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
          {passwordError && (
            <p className="text-red-500 text-sm">{passwordError}</p>
          )}
        </div>
        <div className="mt-2">
          <label className="flex-inline">تأكيد كلمة المرور</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="confirmPassword"
            />
            <button
              type="button"
              className="absolute inset-y-0 left-0 flex items-center px-2" // Adjusted to position right for eye icon
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
          {confirmPasswordError && (
            <p className="text-red-500 text-sm">{confirmPasswordError}</p>
          )}
        </div>
        <div className="my-4">
          <button
            type="button"
            className={`inline-flex justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
              isLoadingse ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleUpdateUserResetPassowrd}
            disabled={isLoadingse}
          >
            {isLoadingse ? "جاري التأكيد..." : "تأكيد"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateUsersData;
